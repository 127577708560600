<template>
  <v-app>
    <SideMenu  v-if="$route.path !== '/' && $route.name !== 'ressetPass'" />
    <TopBar  v-if="$route.path !== '/' && $route.name !== 'ressetPass'"/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import SideMenu from '@/components/widgets/sideMenu.vue'
import TopBar from '@/components/widgets/TopBar'
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
      SideMenu,
      TopBar,
    },
    methods: {
      ...mapMutations(['TOGGLE_ADMIN_SWITCH', 'TOGGLE_AGENT_SWITCH']),
    },
  mounted() {

    if (localStorage.getItem('tokenAdmin')) {
        this.TOGGLE_ADMIN_SWITCH();
    }else if (localStorage.getItem('tokenAgent')) {
        this.TOGGLE_AGENT_SWITCH();
    }

    setInterval(() => {
      console.log("check auth");
      if (this.$route.path !== '/') {
        let auth = Auth.checkAuth();
        if (!auth) {
          this.$router.replace({ name: "login" })
        }
      }
    }, 2 * 60 * 1000);
  },
}
</script>
<style>
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

/* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
}


/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: 'Heebo', arial;
  /* Fallback to arial in case Heebo is not available */
}


/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
