<template>
  <v-navigation-drawer v-model="drawer" :mini-variant="isCollapsed" :mini-variant-width="60" width="350" right app
    permanent elevation="0" :right="$vuetify.rtl" style="background-color: #0d2c6d;">
    <div>

      <div style="display: flex; justify-content: space-between; padding: 7px;">
        <div id="manu_img" @click="logOut()" v-if="!isCollapsed">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <img v-on="on" style="cursor: pointer;" src="@/assets/sideNav/icon_logout.svg">
            </template>
            <span>התנתק</span>
          </v-tooltip>
        </div>

        <div v-if="isAdminSwitched">
          <v-tooltip bottom class="logout-button-container">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" dark @click="swichToAdmin()" medium color="#ffb91a">
                mdi-swap-horizontal
              </v-icon>
            </template>
            <span>חזור לניהול</span>
          </v-tooltip>
        </div>

        <div v-if="isAgentSwitched">
          <v-tooltip bottom class="logout-button-container">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" dark @click="swichToAgent()" medium color="#ffb91a">
                mdi-swap-horizontal
              </v-icon>
            </template>
            <span>חזור לניהול סוכן</span>
          </v-tooltip>
        </div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="toggleDrawer()" class="action-button toggle-button" color="white">
              <v-icon color="#ffb91a">{{ isCollapsed ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ isCollapsed ? 'הרחב תפריט' : 'כווץ תפריט' }}</span>
        </v-tooltip>
      </div>

      <div v-if="!isCollapsed">
        <div onclick="manuoc()" id="manub" style="display: none;">
          <a href="#">
            <img src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/manu.png" width="350"
              height="75" style="
      width: 2vw;
      height: auto;
      float: left;
      border: 0;
      padding-top: 1vh;
      padding-right: .5vh;
     
      right: 15.5%;">
          </a>
        </div>

        <div id="logo" style="text-align: center;"><a href="#">
            <img src="https://cpanel.clickcall.co.il/serverscall/sys_icon/logo.png" width="350" height="75"> </a></div>
        <div id="unlogo">
          <img src="@/assets/sideNav/second_menu_logo.svg" width="350" height="71">
          <div class="centered-text">
            <span>מערכת ניהול</span>
            <span style="color: #ffb91a; font-weight: bold;">מה נשמע פון</span>
          </div>
        </div>
        <!-- <div id="Search">

        <input name="filterText" id="filterText" value="" type="text" onkeyup="reloadTable(); return false;"
          placeholder="חיפוש     " style="color: rgb(169, 169, 169);">
      </div> -->
        <div style="border-bottom: 1px solid #2e4a75;"></div>

        <div id="menu_ul" class="scrollbar-stylel"
          style="overflow: scroll;resize: vertical;direction: ltr; border-bottom: 1px solid #0d2c6d; margin: 0px;">
          <ul id="menu">

            <li class="menu_li" v-for="item in menuItems" :key="item.label">
              <div id="manu_tab">
                <div id="manu_img"><img :src="item.icon">
                </div>
                <div id="manu_link"><a @click="navigate(item.route)">{{ item.label }}</a></div>
              </div>
            </li>

            <li class="menu_li" v-if="role === 'agent'">
              <div id="manu_tab">
                <div id="manu_img"><img src="@/assets/sideNav/icon_users_panel.svg"></div>
                <div id="manu_link" @click="agentRoute()"><a>פאנל ניהול סוכן</a></div>
              </div>
            </li>

            <li class="menu_li" v-if="role === 'admin'">
              <div id="manu_tab">
                <div id="manu_img"><img src="@/assets/sideNav/icon_users_panel.svg"></div>
                <div id="manu_link" @click="adminRoute()"><a>פאנל ניהול כללי</a></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="manu_f_ch scrollbar-style">
        </div>
      </div>

      <!-- Navigation Menu -->
      <div v-else>
        <v-list dense>
          <v-list-item v-for="(item, index) in menuItems" :key="index" @click="navigate(item.route)"
            style="margin-bottom: 10px;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon x-small v-on="on">
                  <img :src="item.icon" :alt="item.label" class="small-icon" />
                </v-btn>
              </template>
              <span>{{ item.label }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </div>


      <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
  </v-navigation-drawer>

</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    SnackBar
  },
  data: () => ({
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    search: "",
    isCollapsed: false,
    drawer: true,
    menuItems: [
      {
        icon: 'https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_1.png',
        label: 'דף הבית',
        route: 'home',
        routeName: 'home'
      },
      {
        icon: require('@/assets/sideNav/icon_account.svg'),
        label: 'אזור אישי',
        route: 'account',
        routeName: 'account'
      },
      {
        icon: 'https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_4.png',
        label: 'שיחות און ליין',
        route: 'onLineUsers',
        routeName: 'onLineUsers'
      },
      {
        icon: 'https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_3.png',
        label: 'דוח שיחות',
        route: 'reports',
        routeName: 'reports'
      },
      {
        icon: require('@/assets/sideNav/icon_tips.svg'),
        label: 'היסטוריית מידע ושימושים',
        route: 'callHistory',
        routeName: 'callHistory'
      },
      {
        icon: require('@/assets/sideNav/icon_upload.svg'),
        label: 'העלאת קבצים',
        route: '/upload/page1',
        routeName: 'upload'
      },
      {
        icon: require('@/assets/sideNav/icon_tfutza.svg'),
        label: 'רשימת תפוצה',
        route: 'subscribesList',
        routeName: 'subscribesList'
      },
      {
        icon: 'https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_7.png',
        label: 'הגדרות',
        route: '/settings/page1',
        routeName: 'settings'
      }
    ]
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    ...mapState(['isAdminSwitched', "isAgentSwitched", "role"]), // Get Vuex state
  },
  methods: {
    ...mapMutations(['TOGGLE_ADMIN_SWITCH', 'TOGGLE_AGENT_SWITCH']), // Import mutation

    swichToAdmin() {
      localStorage.setItem("token", localStorage.getItem("tokenAdmin"));
      localStorage.removeItem("tokenAdmin");

      if (localStorage.getItem("tokenAgent")) {
        localStorage.removeItem("tokenAgent");
      }
      this.TOGGLE_ADMIN_SWITCH();
      this.$router.replace({ path: '/admin/page1' })
    },
    swichToAgent() {
      localStorage.setItem("token", localStorage.getItem("tokenAgent"));
      localStorage.removeItem("tokenAgent");
      this.TOGGLE_AGENT_SWITCH();
      this.$router.replace({ path: '/agent/page1' })
    },
    navigate(route) {
      if (route.includes('/')) {
        if (this.$route.path !== route) {
          this.$router.push({ path: route });
        }
      } else {
        if (this.$route.name !== route) {
          this.$router.push({ name: route });
        }
      }
    },
    agentRoute() {
      if (this.$route.name !== 'agent') {
        this.$router.push({ path: '/agent/page1' });
      }
    },
    adminRoute() {
      if (this.$route.name !== 'admin') {
        this.$router.push({ path: '/admin/page1' });
      }
    },
    logOut() {
      localStorage.removeItem("token");
      this.$router.replace({ name: 'login' });
    },
    toggleDrawer() {
      this.isCollapsed = !this.isCollapsed;
    },
    handleMouseEnter() {
      console.log(33333);

      if (this.isCollapsed) {
        this.isCollapsed = false;
      }
    },
  },
  mounted() {
  },
}
</script>
<style scoped>
ul {
  list-style: none;
  padding-right: 0%;
  /* margin-bottom: 10%; */
  /* border-top: 0.1vh solid #5b565d; */
  /* border-bottom: 0.1vh solid #010c1e; */
}

#logo {
  margin-top: 6%;
  margin-bottom: 3%;
}

#logo img {
  width: 70%;
  height: auto;
}

#unlogo img {
  width: 100%;
  height: auto;
}

#unlogo {
  position: relative;
  width: 100%;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Adjust text color as per requirement */
  text-align: center;
}

.centered-text span {
  display: block;
  font-weight: 500;
  font-size: medium;
}

#Search>#filterText {
  width: 96%;
  border: 0 solid #ccc;
  font-size: 19px;
  background-color: #214883;
  background-image: url("@/assets/sideNav/icon_Search.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  padding: 4%;
  color: #a6bbcc;
  margin: 0 2%;
  font-size: 1.2em;
  background-size: auto 100%;
}

.menu_li {
  cursor: pointer;
  /* background: url(../../../../assets/images/v3/system/chevron_left.png); */
  background-repeat: no-repeat;
  background-position: 1vh;
  background-size: 3.5vh 3.5vh;
  /* border-top: .01vh solid #010c1e; */
  border-bottom: .01vh solid #2e4a75;
  /* filter: invert(100%); */
  padding-top: 2px;
}

.li_arrow {
  background: none;
}

.menu_li a {
  font-size: 1.2em;
  display: block;
  padding: 5%;
  color: #fff;
  text-decoration: none;
}

li img {
  width: 80%;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

::placeholder {
  color: #a6bbcc;
}


div#manu_tab {
  display: table;
  width: 100%;
}

div#manu_img {
  display: table-cell;
  height: 100%;
  margin-bottom: -.85vh;
  vertical-align: middle;
  width: 15%;
  text-align: center;
}

div#manu_link {
  display: table-cell;
  font-family: heebo, arial;
  vertical-align: middle;
}


div#manu_link>a:hover {
  background: #fff;
  color: rgb(13, 44, 109);
  text-decoration: none;
}


div#customFilter input#filterText {
  display: none;
}

div.manu_f_ch {
  max-height: 30%;
  height: 20%;
  overflow: auto;
}


.seemykdk>div>img {
  left: 1px;
  top: 1px;
}



header .right li {
  padding: 0 10px 0 9px;
  float: left;

  height: 20px;
  vertical-align: top;
}

ul#menu {
  direction: rtl;
  margin: 0;
  margin-top: 10px;
  padding-left: 0px !important;
}

.scrollbar-stylel::-webkit-scrollbar-track {
  border: none;
  background-color: #0d2c6d;
}

.scrollbar-stylel::-webkit-scrollbar {
  width: 5px;
  background-color: #0d2c6d;
  height: 1px;
}

.scrollbar-stylel::-webkit-scrollbar-thumb {
  background-color: #0d2c6d;
}

.scrollbar-stylel::-webkit-resizer {
  background-color: #0d2c6d;
}


:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
}

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.small-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
</style>