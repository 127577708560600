<template>
  <div id="topbar">
    <div id="topbar-span">
      <span><b>מחובר: </b>{{ userName + " " + business_name}}
        <a class="user" href="#" @click="showLogs()">{{ lineNumber }}</a>
        <!-- <a class="user" href="https://cpanel.clickcall.co.il/serverscall/admin/user_edit.php?id=6757">{{ lineNumber}}</a> -->
      </span>
    </div>
  </div>
</template>
<script>
import Auth from '@/services/auth.service.js'
import { mapState, mapMutations } from 'vuex';

export default {
  data: () => ({
    role: ""
  }),
  methods: {
    getUserDetails() {
      this.role = Auth.getUserRole();
    },
    showLogs() {
      if (this.role === "admin") {
        this.$router.push({ name: 'logs' })
      }
    }
  },
  computed: {
    ...mapState(['lineNumber', "userName", "business_name"]), // Get Vuex state
  },
  created() {
    this.getUserDetails();
  },
}
</script>
<style scoped>
div#topbar {
  width: 100%;
  height: 35px;
  background: #ffb91a;
  max-height: 35px;
}

#topbar>#topbar-span>span {
  text-align: left;
  display: inline-block;
  padding: 8px;
  margin-left: 25px;
  font-size: 1.1em;
}

#topbar>span>a {
  color: #0d2c6d;
}

div#topbar-span {
  width: 100%;
  height: 35px;
  background: #ffb91a;
  max-height: 35px;
  overflow: hidden;
  direction: ltr;
}

</style>

  