<template>
  <div style="margin-top: 10px;">
    <div class="sectionLargeIcon largeServerIcon"></div>
    <div class="widget clearfix">
      <span class="title" style="font-size: 22px; margin-right: 15px; font-weight: 600 !important; color: #183677;">
        הגדרות</span>
      <div class="widget_inside" style="margin-top: 15px;">
        <SettingsMenu @barSettingsItemSelected="openSelected" :routerSelected="routerSelected" />

        <FirstSettingsComp v-if="itemSelected === 'page1'" />
        <SecondSettingsComp v-if="itemSelected === 'page2'" />
        <ThirdSettingsComp v-if="itemSelected === 'page3'" />
        <FourhSettingsComp v-if="itemSelected === 'page4'" />
        <FifthSettingsComp v-if="itemSelected === 'page5'" />
      </div>
    </div>
  </div>
</template>
<script>
import FirstSettingsComp from '@/components/settingspage/FirstSettingsComp'
import SecondSettingsComp from '@/components/settingspage/SecondSettingsComp'
import ThirdSettingsComp from '@/components/settingspage/ThirdSettingsComp'
import FourhSettingsComp from '@/components/settingspage/FourhSettingsComp'
import FifthSettingsComp from '@/components/settingspage/FifthSettingsComp'
import Auth from '@/services/auth.service.js'
import SideMenu from '@/components/widgets/sideMenu.vue'
import SettingsMenu from '@/components/settingspage/SettingsMenu'
import TopBar from '@/components/widgets/TopBar'

export default {
  name: 'agent',
  components: {
    SideMenu,
    TopBar,
    SettingsMenu,
    FirstSettingsComp,
    SecondSettingsComp,
    ThirdSettingsComp,
    FourhSettingsComp,
    FifthSettingsComp
  },
  data: () => ({
    itemSelected: "page1",
    routerSelected: "",
    isExtended: true,
  }),
  methods: {
    openSelected(itemNum) {
      this.itemSelected = itemNum;
    },
    isExtendedFunc() {
      this.isExtended = !this.isExtended;
    }
  },
  created() {
    document.title = 'הגדרות | clickcall'
    const pageParam = this.$route.params.page;
    if (pageParam) {
      this.routerSelected = pageParam;
      this.openSelected(pageParam)
    } else {
      this.openSelected("page1")
    }

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
#mainContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}

div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  background: #fbfbfb;
}

/* costume scroolbar body */
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

/* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
}
</style>