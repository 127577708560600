<template>
  <div style="margin-top: 10px;">
    <div class="sectionLargeIcon largeServerIcon"></div>
    <div class="widget clearfix">
      <span class="title" style="font-size: 22px; margin-right: 15px; font-weight: 600 !important; color: #183677;">
        הסטוריית שיחות</span>
      <div class="widget_inside" style="margin-top: 15px;">
        <CallHistoryComp />
      </div>
    </div>
  </div>
</template>
<script>
import CallHistoryComp from '@/components/callHistory/CallHistoryComp'
import Auth from '@/services/auth.service.js'

export default {
  name: 'upload',
  components: {
    CallHistoryComp,
  },
  created() {
    document.title = 'הסטוריית שיחות | clickcall'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
