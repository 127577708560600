<template>
  <div style="margin-top: 10px;">
    <div class="sectionLargeIcon largeServerIcon"></div>
    <div class="widget clearfix">
      <span class="title" style="font-size: 22px; margin-right: 15px; font-weight: 600 !important; color: #183677;">
        העלאת קבצים</span>
      <div class="widget_inside" style="margin-top: 15px;">
        <UploadMenu @barUploadItemSelected="openSelected" :routerSelected="routerSelected" />

        <FirstComp v-if="itemSelected === 'page1'" />
        <SecondComp v-if="itemSelected === 'page2'" />
        <ThirdComp v-if="itemSelected === 'page3'" />
        <FourhComp v-if="itemSelected === 'page4'" />
      </div>
    </div>
  </div>
</template>
<script>
import FirstComp from '@/components/upload/FirstComp'
import SecondComp from '@/components/upload/SecondComp'
import ThirdComp from '@/components/upload/ThirdComp'
import FourhComp from '@/components/upload/FourhComp'
import Auth from '@/services/auth.service.js'
import SideMenu from '@/components/widgets/sideMenu.vue'
import UploadMenu from '@/components/upload/UploadMenu'
import TopBar from '@/components/widgets/TopBar'

export default {
  name: 'upload',
  components: {
    SideMenu,
    TopBar,
    UploadMenu,
    FirstComp,
    SecondComp,
    ThirdComp,
    FourhComp
  },
  data: () => ({
    itemSelected: "page1",
    routerSelected: "",
    isExtended: true,
  }),
  methods: {
    openSelected(itemNum) {
      this.itemSelected = itemNum;
    },
    isExtendedFunc() {
      this.isExtended = !this.isExtended;
    }
  },
  created() {
    document.title = 'קבצים | clickcall'
    const pageParam = this.$route.params.page;
    if (pageParam) {
      this.routerSelected = pageParam;
      this.openSelected(pageParam)
    } else {
      this.openSelected("page1")
    }

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
#mainContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}

div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  background: #fbfbfb;
}


/* costume scroolbar body */
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;

}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

/* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
}
</style>