import ApiServices from '@/services/api.service'

class sharedFunctions {
    constructor() {
    }

    // RETURN A NICE DATE STRING
    static async getCountryCodes() {
        try {
            let token = localStorage.getItem("token");
            let api = process.env.VUE_APP_BASE_URL + "/settings/get_country_codes";
            const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
            const jsonObject = await res.json();
            if (res.status === 200) {
                return jsonObject.country_codes;
            }else{
                return [];
            }
        } catch (error) {
            this.progressShow = false;
            this.showSnackBar("שגיאה  בקבלת רשימת מדינות: " + error, "red");
        }
    }


}

export default sharedFunctions;

