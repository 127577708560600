<template>
  <div style="margin-top: 30px;">
   <AdminView @barUploadItemSelected="openSelected" :routerSelected="routerSelected" />
  
  <FirstAdminComp v-if="itemSelected === 'page1'" />
  <SecondAdminComp v-if="itemSelected === 'page2'" />
  <ThirdAdminComp v-if="itemSelected === 'page3'" />
  <FourhAdminComp v-if="itemSelected === 'page4'" />
  <FifthAdminComp v-if="itemSelected === 'page5'" />
  </div>
  </template>

  <script>
  import FirstAdminComp from '@/components/admin/FirstAdminComp'
  import SecondAdminComp from '@/components/admin/SecondAdminComp'
  import ThirdAdminComp from '@/components/admin/ThirdAdminComp'
  import FourhAdminComp from '@/components/admin/FourhAdminComp'
  import FifthAdminComp from '@/components/admin/FifthAdminComp'
  import Auth from '@/services/auth.service.js'
  import AdminView from '@/components/admin/AdminMenu'
  
  export default {
    name: 'agent',
    components: {
      AdminView,
      FirstAdminComp,
      SecondAdminComp,
      ThirdAdminComp,
      FourhAdminComp,
      FifthAdminComp
    },
    data: () => ({
      itemSelected: "page1",
      routerSelected: "",
    }),
    methods: {
      openSelected(itemNum) {
        this.itemSelected = itemNum;
      },
    },
    created() {
      document.title = 'מנהל | clickcall'
      const pageParam = this.$route.params.page;
      if (pageParam) {
        this.routerSelected = pageParam;
       this.openSelected(pageParam)
      }else{
        this.openSelected("page1")
      }
  
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    },
  }
  </script>
  <style scoped>
  
  /* costume scroolbar body */
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

 /* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
    width: 0px !important;
}
  </style>