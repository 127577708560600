<template >
    <div>

        <div class="my-container">
            <span>הוספת מספר חדש:</span>
            <v-btn color="#adacaa2c" class="gradient-button-background" @click="openCreateDIalog()">הוספת מספר
                בודד</v-btn>
            <v-btn color="#adacaa2c" class="gradient-button-background" @click="importDialog = true">הוספת מספרים
                מאקסל</v-btn>
            <v-btn color="#adacaa2c" class="gradient-button-background" @click="sendCallToAllDialog = true">שלח צינתוק לכל
                החברים</v-btn>
        </div>

        <v-row style="margin-right: 5% !important;  display: flex; align-items: center; width: 90%;">
            <v-col cols="12" md="3" sm="4">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="4">
                <span style="margin-inline-start: 10px; font-weight: 600;">סך הכל {{ subscribes.length }} אנשי קשר</span>
            </v-col>
            <v-col cols="12" md="6" sm="4" style="direction: ltr;">
                <v-btn color="#adacaa2c" max-width="200" class="gradient-button-background"
                    @click="exportSubscribers()">ייצוא דוח</v-btn>
            </v-col>
        </v-row>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <!-- <v-data-table id="table1" :headers="headers" :items="subscribes" sort-by="createdAt" sort-desc
                    :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }"> -->

                <v-data-table id="table1" :headers="headers" :items="subscribes" sort-by="createdAt" sort-desc
                    :search="search" :items-per-page="itemsPerPage" :footer-props="{
                        'items-per-page-options': [20, 50, 100, 500, 1000, -1],
                        'items-per-page-text': 'שורות בעמוד:'
                    }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.name }}</td>
                            <td class="table-cells">{{ item.phone }}</td>
                            <td class="table-cells">{{ item.added_by }}</td>
                            <td class="table-cells">{{ item.role }}</td>
                            <td class="table-cells">{{ item.createdAt }}</td>
                            <td class="table-cells">{{ item.lastCall }}</td>
                            <td class="table-cells"><a style="text-decoration: underline;"
                                    @click="openSettingsDialog(item, index)">הגדרות</a></td>
                            <td class="table-cells">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                            style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;"
                                            class="audioButtons" @click="openEditDialog(item, index)">
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                            style="margin: 10px; cursor: pointer;" class="audioButtons"
                                            @click="openDeleteDialog(item, index)">
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.record_name="{ item, index }">
                        <a style="text-decoration: underline;" @click="openSettingsDialog(item, index)">הגדרות</a>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;" class="audioButtons"
                                    @click="openEditDialog(item, index)">
                            </template>
                            <span>עריכה</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_delete.svg" v-on="on" style="margin: 10px; cursor: pointer;"
                                    class="audioButtons" @click="openDeleteDialog(item, index)">
                            </template>
                            <span>מחיקה</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין אנשי
                                קשר</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>


            </div>
        </div>
        <DeleteItemDialog v-model="deleteDialog" v-if="deleteDialog" :item="itemToDelete" @itemDeleted="itemDeleted">
        </DeleteItemDialog>

        <TzintukSettingsDialog v-model="settingsDialog" v-if="settingsDialog" :item="itemSettings"
            @itemSettingsChange="itemSettingsChange" />

        <SendCallToAllDialog v-model="sendCallToAllDialog" v-if="sendCallToAllDialog" />

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @subscribeCreated="newSubscribeSaved" @subscribeEdited="editedSubscribeSaved"></AddEditDialog>

        <ImportFromExcelDialog v-model="importDialog" v-if="importDialog" @ImportExcel="ImportExcel"
            @exportExampleExcel="exportExampleExcel"></ImportFromExcelDialog>

        <ImportMessageDialog v-model="importMessageDialog" v-if="importMessageDialog" :importedSuccess="importedSuccess"
            :importedFailed="importedFailed" @importedSuccessAdded="importedSuccessAdded"></ImportMessageDialog>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'
import DeleteItemDialog from '@/components/subscribesList/dialogs/DeleteItemDialog'
import TzintukSettingsDialog from '@/components/subscribesList/dialogs/TzintukSettingsDialog'
import AddEditDialog from '@/components/subscribesList/dialogs/AddEditDialog'
import ImportFromExcelDialog from '@/components/subscribesList/dialogs/ImportExcel'
import ImportMessageDialog from '@/components/subscribesList/dialogs/ImportMessageDialog'
import SendCallToAllDialog from '@/components/subscribesList/dialogs/SendCallToAllDialog'
import TableToExcel from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    components: {
        SnackBar,
        DeleteItemDialog,
        AddEditDialog,
        ImportFromExcelDialog,
        ImportMessageDialog,
        TzintukSettingsDialog,
        SendCallToAllDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        addEditDialog: false,
        settingsDialog: false,
        importDialog: false,
        importMessageDialog: false,
        importedFailed: [],
        importedSuccess: [],
        formTitle: "",
        itemToDelete: {},
        editedItem: {},
        itemSettings: {},
        deleteDialog: false,
        sendCallToAllDialog: false,
        search: "",
        subscribes: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'שם', align: 'start', value: 'name' },
            { text: 'טלפון', value: 'phone' },
            { text: 'באמצעות', value: 'added_by' },
            { text: 'הרשאות', value: 'role' },
            { text: 'התחבר בתאריך', value: 'createdAt' },
            { text: 'התקשרות אחרונה', value: 'lastCall' },
            { text: 'צינתוק', value: 'record_name' },
            { text: '', value: 'actions', sortable: false },

        ],
        itemsPerPage: 20, // default items per page
    }),
    methods: {

        async getSubscribes() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/subscribes/get_subscribes_list`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.subscribes = jsonObject;
                    console.log(this.subscribes);
                    this.progressShow = false;
                    this.formatDetails();
                }
            } catch (error) {
                this.showSnackBar("Error get subscribes: " + error, "red");
            }
        },
        formatDetails() {
            this.subscribes = this.subscribes.map((subscribe) => {
                return {
                    ...subscribe,
                    createdAt: utils.getCurrentDate(subscribe.createdAt),
                    lastCall: utils.getCurrentDate(subscribe.lastCall),
                    added_by: subscribe.added_by === "WEBSITE" ? "אתר הניהול" : subscribe.added_by
                    // extension: record.extension === "regular" ? "שלוחת הודעות" : "הודעה למנהל"
                }
            });
        },
        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.deleteDialog = true
        },
        openEditDialog(item, index) {
            this.formTitle = "ערוך איש קשר"
            this.editedItem = item
            this.editedItem.itemIndex = index;
            this.addEditDialog = true
        },
        openSettingsDialog(item, index) {
            this.itemSettings = item
            this.settingsDialog = true
        },
        openCreateDIalog() {
            this.formTitle = "הוספת איש קשר חדש:"
            this.editedItem = {}
            this.addEditDialog = true
        },
        newSubscribeSaved(item, text, color) {

            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.subscribes.push(item);

        },
        editedSubscribeSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.subscribes.findIndex(subscribe => subscribe._id === item._id);

            let g = Object.assign(this.subscribes[index], item);

        },
        itemDeleted(id, text, color) {
            if (id === "") {
                this.showSnackBar(text, color)
                return
            }
            // Use splice to remove the item at the specified index
            const index = this.subscribes.findIndex(subscribe => subscribe._id === id);
            this.subscribes.splice(index, 1);
            this.showSnackBar(text, color)
        },
        itemSettingsChange(item, text, color) {
            if (!item) {
                this.showSnackBar(text, color)
                return
            }
            const index = this.subscribes.findIndex(subscribe => subscribe._id === item._id);
            let g = Object.assign(this.subscribes[index], item);
            this.showSnackBar(text, color)
        },
        async exportExampleExcel() {

            // Create a copy of the headers with English text
            const englishHeaders = [
                { text: '#', value: "index", sortable: false },
                { text: 'name', align: 'start', value: 'name' },
                { text: 'phone', value: 'phone' },
                { text: 'callerid_country_code', value: 'callerid_country_code' },
            ];

            const table = document.getElementById('table1');
            const clonedTable = table.cloneNode(true);

            // Replace the table headers with the English headers in the cloned table
            const tableHeaders = clonedTable.getElementsByTagName('th');
            for (let i = 0; i < englishHeaders.length; i++) {
                tableHeaders[i].textContent = englishHeaders[i].text;
            }

            // Get all rows of the cloned table
            const rows = clonedTable.querySelectorAll('tr');
            // Iterate over each row
            rows.forEach(row => {
                // Get all header cells in the row
                const headerCells = Array.from(row.querySelectorAll('th'));
                // Get all data cells in the row
                const dataCells = Array.from(row.querySelectorAll('td'));

                // Remove all data cells
                dataCells.forEach(cell => cell.remove());

                // Remove all header cells except for the 2nd, 3rd
                headerCells.forEach((cell, index) => {
                    if (index !== 1 && index !== 2 && index !== 3) {
                        cell.remove();
                    }
                });
            });
            // Now the clonedTable only contains the desired header columns
            TableToExcel.convert(clonedTable, {
                name: 'exampleFile.xlsx',
                sheet: {
                    name: 'Sheet 1',
                },
            });

        },
        async exportSubscribers() {
            this.setItemsPerPage(-1); // Set to display all items
            // Wait for the next tick to ensure the table is updated
            this.$nextTick(() => {
                // Now export the table as you normally would
                const table = document.getElementById('table1');
                const clonedTable = table.cloneNode(true);

                TableToExcel.convert(clonedTable, {
                    name: 'subscribers.xlsx',
                    sheet: {
                        name: 'Sheet 1',
                    },
                });

                // Optionally, reset the itemsPerPage after export
                this.setItemsPerPage(20); // Or any default value
            });
        },
        // Method to set items per page
        setItemsPerPage(value) {
            this.itemsPerPage = value;
        },
        ImportExcel(file) {

            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);

                    for (let i = 0; i < jsonData.length; i++) {
                        const row = jsonData[i];
                        let { name, phone, callerid_country_code } = row;

                        // Ensure phone is a string
                        phone = String(phone);

                        // Valid subscribe not empty
                        if (!phone && !name) {
                            console.log("error row is empty");
                            continue; // Skip to the next iteration if the format is invalid
                        }

                        // Valid subscribe phone exsit
                        if (!phone) {
                            console.log("error phone is empty");
                            this.importedFailed.push(phone)
                            continue; // Skip to the next iteration if the format is invalid
                        }

                        // Valid subscribe phone start with 0
                        if (!phone.startsWith("0")) {
                            phone = "0" + phone;  // Prepend "0" to the phone number
                        }

                        // Valid subscribe phone start with 0
                        if (!phone.startsWith("0")) {
                            phone = "0" + phone;  // Prepend "0" to the phone number
                        }

                        // Check if the phone number's length is less than 9
                        if (phone.length < 9) {
                            console.log("Phone number is shorter than 9 characters.");
                            this.importedFailed.push(phone)
                            continue; // Skip to the next iteration if the format is invalid
                        }

                        const numberRegex = /^\d+$/; // Only numeric characters
                        const isValidFormat = numberRegex.test(phone);
                        if (!isValidFormat) {
                            console.log("error format phone");
                            this.importedFailed.push(phone)
                            continue; // Skip to the next iteration if the format is invalid
                        }

                        // Check for duplicates
                        const isDuplicate = this.subscribes.some((subscribe) => subscribe.phone === phone);
                        if (isDuplicate) {
                            console.log("error duplicate coulmn " + phone);
                            this.importedFailed.push(phone)
                            continue; // Skip to the next iteration if a duplicate is found
                        }

                        const rowData = {
                            name, phone, callerid_country_code
                        };

                        this.importedSuccess.push(rowData)
                    }

                    this.importMessageDialog = true;

                } catch (error) {
                    this.showSnackBar('Error parsing Excel file: ' + error, "red");
                }
            };
            reader.onerror = (e) => {
                this.showSnackBar('Error reading file: ' + e.target.error, "red");
            };
            reader.readAsArrayBuffer(file);

        },
        importedSuccessAdded() {
            this.showSnackBar('אנשי הקשר נוספו בהצלחה!', "green");
            this.getSubscribes();
        },
    },
    computed: {
        dynamicStyles() {
            return {
                width: this.$vuetify.breakpoint.mdAndUp ? '40%' : '90%',
                flexDirection: this.$vuetify.breakpoint.mdAndUp ? 'row' : 'column',
                alignItems: this.$vuetify.breakpoint.mdAndUp ? 'center' : '',
            };
        }
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getSubscribes();

    },
}
</script>
<style scoped>
.my-container {
    display: flex;
    align-items: center;
    margin-right: 6%;
    margin-bottom: 70px;
}

.my-container span {
    font-size: x-large;
}

.my-container .v-btn {
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 500;
    font-size: medium;
    color: black;
    /* Adjusted color property here */
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .my-container {
        flex-direction: column;
        align-items: start;
        margin-right: 2%;
        margin-bottom: 35px;
    }

    .my-container span {
        font-size: large;
        margin-bottom: 10px;
    }

    .my-container .v-btn {
        margin-top: 15px;
        font-size: small;
    }
}

body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */


#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    