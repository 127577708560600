<template>
    <v-container fluid>
      <v-tabs v-model="activeTab" background-color="#0d2c6d" dark>
        <v-tab>שיחות נכנסות</v-tab>
        <v-tab>שיחות יוצאות</v-tab>
        <v-tab>זיהוי בשיחות יוצאות</v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="activeTab">
        <!-- Incoming Rules -->
        <v-tab-item>
          <rules-table direction="incoming" title="ניהול כללי שיחות נכנסות"></rules-table>
        </v-tab-item>
  
        <!-- Outgoing Rules -->
        <v-tab-item>
          <rules-table direction="outgoing" title="ניהול כללי שיחות יוצאות"></rules-table>
        </v-tab-item>

          <!-- CallerId Rules -->
          <v-tab-item>
          <rules-table direction="callerId" title="ניהול כללי זיהוי לשיחות יוצאות"></rules-table>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import RulesTable from './RulesTable.vue';
  
  export default {
    components: { RulesTable },
    data: () => ({ activeTab: 0 }),
  };
  </script>
  