import { render, staticRenderFns } from "./FirstAgentComp.vue?vue&type=template&id=097d85af&scoped=true&"
import script from "./FirstAgentComp.vue?vue&type=script&lang=js&"
export * from "./FirstAgentComp.vue?vue&type=script&lang=js&"
import style0 from "./FirstAgentComp.vue?vue&type=style&index=0&id=097d85af&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097d85af",
  null
  
)

export default component.exports