<template>
  <div style="margin-top: 10px;">
    <div class="sectionLargeIcon largeServerIcon"></div>
    <div class="widget clearfix">
      <span class="title" style="font-size: 22px; margin-right: 15px; font-weight: 600 !important; color: #183677;">
      אזור אישי  </span>
      <div class="widget_inside" style="margin-top: 15px;">
        <AccountComp />
      </div>
    </div>
  </div>
  </template>
  <script>
  import AccountComp from '@/components/account/AccountComp'
  import Auth from '@/services/auth.service.js'
  
  export default {
    name: 'upload',
    components: {
      AccountComp,
    },
    created() {
      document.title = 'אזור אישי | clickcall'
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    },
  }
  </script>
  