<template >
  <div>
    <div id="loginback">
      <div id="login">

        <form @submit.prevent="login">
          <input id="username" name="username" type="text" size="40" class="login_name" v-model="username"
            aria-required="true" aria-invalid="false" placeholder="שם משתמש" required>
          <input id="password" name="password" type="password" size="40" class="login_password" v-model="password"
            placeholder="סיסמא" required>
          <input type="submit" value="הכנס" class="login_submit"><br>
        </form>

        <div style="display: flex; justify-content: space-between; width: 95%;">
          <div><span @click="forgotPassword" class="Back">שכחת סיסמה?</span></div>
          <div><span @click="openRegister" class="Back">צור חשבון</span></div>
        </div>
        <input id="submitme" name="submitme" value="1" type="hidden">

      </div>
    </div>

    <div class="centered-text">
      <div id="foter">
        <span style="color: white; font-weight: bold; font-size: 40px; margin-left: 20px;">מערכת ניהול</span>
        <span style="color: #ffb91a; font-weight: bold; font-size: 40px; margin-left: 20px; display: block;">מה נשמע
          פון</span>
      </div>
    </div>

    <template>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import { mapState, mapMutations } from 'vuex';

export default {

  components: {
    SnackBar
  },

  data: () => ({
    userName: "",
    password: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    overlay: false,
  }),
  methods: {
    ...mapMutations(['TOGGLE_USER_DETAILS']), // Import mutation

    async login() {
      const lineNumber = document.getElementById('username').value;
      const password = document.getElementById('password').value;

      this.progressShow = true
      let MyJSON = JSON.stringify({ lineNumber, password });
      let api = process.env.VUE_APP_BASE_URL + "/auth/login"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          this.showSnackBar("התחברת בהצלחה!", "green");
          localStorage.setItem('token', jsonObject.token);
          this.TOGGLE_USER_DETAILS();
          this.$router.replace({ name: "home" })
        } else {
          this.showSnackBar("שגיאת שרת", "red");
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }

      // }
    },
    async forgotPassword() {
      const lineNumber = document.getElementById('username').value;

      if (!lineNumber) {
        return this.showSnackBar("יש להזין שם משתמש", "red");
      }

      this.overlay = true
      let MyJSON = JSON.stringify({ lineNumber });
      let api = process.env.VUE_APP_BASE_URL + "/auth/forgotPassword"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.overlay = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          this.showSnackBar("נשלח אליכם אימייל לאיפוס הסיסמה", "green");
        } else {
          this.showSnackBar("שגיאת שרת", "red");
        }
      } catch (error) {
        this.showSnackBar("Error Login: " + error, "red");
      }
    },
    openRegister() {
      this.$emit("openRegister");
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    handleEnterKeyOtp(event) {
      if (event.keyCode === 13) {
        this.login(); // Trigger the button click event
      }
    },
  },
  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL'
    },
  },
  mounted() {
    document.title = 'Login | CLICK-CALL'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleEnterKeyOtp);

  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);

    // Remove the event listener when the component is about to be destroyed
    window.removeEventListener('keydown', this.handleEnterKeyOtp);
  },
}
</script>
<style scoped>
#login {
  width: 65%;
  margin: 4% auto;
  min-height: 40%;
}

div#loginback {
  background: #0d2c6d;
  min-height: 55%;
  position: relative;
  z-index: 99;
}

.login_name {
  font-family: heebo, arial !important;
  border: 0 none !important;
  text-align: right !important;
  padding: 3% 13% 3% 5% !important;
  font-size: 1.25em !important;
  width: 95% !important;
  margin-bottom: 3% !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: 100% -.9vh !important;
  border-radius: .4vw !important;
  background-color: white;
  background-image: url(@/assets/login/icon_u.png);
}

.login_password {
  font-family: heebo, arial;
  border: 0 none;
  text-align: right;
  padding: 3% 13% 3% 5%;
  width: 95%;
  font-size: 1.25em;
  margin-bottom: 6%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% -.9vh;
  border-radius: .4vw;
  background-color: white;
  background-image: url(@/assets/login/icon_s.png);
}

.login_submit {
  font-family: heebo, arial;
  padding: 2%;
  background: #fdb721;
  width: 95%;
  font-size: 1.57em;
  margin-bottom: 2%;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  border-radius: .4vw;
}

.forgot {
  text-align: right;
  direction: rtl;
  float: right;
  color: #fff;
  font-size: 1.11em;
  font-family: heebo, arial;
  margin: 1% 0%;
}

.Back {
  text-align: right;
  direction: rtl;
  color: #fff;
  font-size: 1.11em;
  font-family: heebo, arial;
  margin: 1% 0%;
  cursor: pointer;
}

#rememberme {
  float: right;
  margin: 2% 2% 2%;
  /* height: 1.5vh; */
  /* width: 1.5vh; */
  /* border-radius: 0; */
  /* border: none; */
}


#foter {
  background-image: url("@/assets/footer.svg");
  background-size: 108% auto;
  background-repeat: no-repeat;
  width: 34%;
  height: 16vh;
  position: absolute;
  bottom: 2%;
  z-index: 0;
}



.centered-text {
  text-align: center;
}


/* end .squaredFour */


@media only screen and (max-width: 998px) and (min-height: 600px) {

  #login {
    width: 85%;
  }

  .squaredFour label {
    width: 3.5vh;
    height: 3.5vh
  }




  #foter {

    width: 100%;

    position: absolute;

    bottom: 5%;
  }

}


.notification {
  display: block;
  padding: 3% 13% 3% 5%;
  width: 76%;
  border-radius: .4vw;
  margin-bottom: 2vh;
}

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
