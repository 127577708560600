import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import store from './store'; // ✅ Import Vuex store

Vue.config.productionTip = false
Vue.use(DatetimePicker)
Vue.use(VueReCaptcha, {
  siteKey: "6LcXWhAqAAAAACIv9hfQKvRVZcqL-FBTPq_S2MB7"
})
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
