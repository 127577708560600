<template>
    <div style="margin-top: 10px;">
    <div class="sectionLargeIcon largeServerIcon"></div>
    <div class="widget clearfix">
      <span class="title" style="font-size: 22px; margin-right: 15px; font-weight: 600 !important; color: #183677;">
        דוח שיחות</span>
      <div class="widget_inside" style="margin-top: 15px;">
        <ReportsComp />
      </div>
    </div>
  </div>
  </template>
  <script>
  import ReportsComp from '@/components/callsReport/CallsReportComp'
  import Auth from '@/services/auth.service.js'
  
  export default {
    name: 'upload',
    components: {
      ReportsComp,
    },
    created() {
      document.title = 'דוח שיחות | clickcall'
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    },
  }
  </script>
  <style scoped>
  
  
  /* costume scroolbar body */
/* .scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

:not(.scrollbar-style)::-webkit-scrollbar {
    width: 0px !important;
} */

  </style>