<template>
  <v-card class="mt-3">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="openDialog()">הוסף כלל חדש</v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="rules" item-key="_id" class="elevation-1">
      <template v-slot:item.active="{ item }">
        <v-switch v-model="item.active" small @change="toggleActive(item._id, item.active)"></v-switch>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="openDialog(item)">mdi-pencil</v-icon>
        <v-icon small @click="confirmDelete(item)" color="red">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- Dialog to Add/Edit -->
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>{{ editedRule._id ? 'ערוך חוק' : 'הוסף חוק חדש' }}</v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field label="ספק" v-model="editedRule.provider" required></v-text-field>
            <v-text-field label="תיאור הכלל" v-model="editedRule.description"></v-text-field>

            <v-select v-model="editedRule.conditionOperator" :items="conditionOperators" label="קשר בין תנאים" dense
              required></v-select>

            <v-btn small color="primary" @click="addCondition" class="mb-4">הוסף תנאי</v-btn>

            <div v-for="(cond, i) in editedRule.conditions" :key="i">
              <v-row dense align="center">
                <v-col cols="4">
                  <v-select v-model="cond.type" :items="conditionTypes" label="סוג התנאי" dense required></v-select>
                </v-col>
                <v-col cols="5">
                  <v-text-field v-model="cond.value" label="ערך התנאי" dense required></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn icon small color="red" @click="removeCondition(i)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <v-divider class="my-2"></v-divider>

            <v-row dense>
              <v-col cols="6">
                <v-text-field label="החלף טקסט זה (בתחילת המספר)" v-model="editedRule.matchText"
                  required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="החלף ב..." v-model="editedRule.replaceText" required></v-text-field>
              </v-col>
            </v-row>

            <v-text-field label="עדיפות" type="number" v-model.number="editedRule.priority"></v-text-field>
            <v-switch label="פעיל" v-model="editedRule.active"></v-switch>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">ביטול</v-btn>
          <v-btn color="success" @click="saveRule">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    direction: { type: String, required: true },
    title: { type: String, required: true },
  },
  data() {
    return {
      rules: [],
      dialog: false,
      editedRule: {
        provider: '',
        description: '',
        conditionOperator: 'AND',
        conditions: [],
        matchText: '',
        replaceText: '',
        priority: 0,
        active: true,
      },
      conditionTypes: [
        { text: 'מתחיל ב...', value: 'startsWith' },
        { text: 'אורך המספר שווה ל...', value: 'length' },
      ],
      conditionOperators: [
        { text: 'כל התנאים נכונים (וגם)', value: 'AND' },
        { text: 'לפחות תנאי אחד נכון (או)', value: 'OR' },
      ],
      headers: [
        { text: 'ספק', value: 'provider' },
        { text: 'תיאור החוק', value: 'description' },
        { text: 'תבנית לזיהוי', value: 'matchText' },
        { text: 'החלף ל', value: 'replaceText' },
        { text: 'עדיפות', value: 'priority' },
        { text: 'פעיל', value: 'active' },
        { text: 'פעולות', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchRules();
  },
  methods: {
    getAuthHeaders() {
      let token = localStorage.getItem("token");
      return { Authorization: `Bearer ${token}` };
    },
    openDialog(item = {}) {
      this.editedRule = {
        direction: this.direction,
        active: item.active ?? true,
        provider: item.provider || '',
        description: item.description || '',
        conditionOperator: item.conditionOperator || 'AND',
        conditions: item.conditions?.length ? JSON.parse(JSON.stringify(item.conditions)) : [],
        matchText: item.matchText || '',
        replaceText: item.replaceText || '',
        priority: item.priority ?? 0,
        _id: item._id || null
      };
      this.dialog = true;
    },

    async fetchRules() {
      const { data } = await axios.get(process.env.VUE_APP_BASE_URL + '/rules/call-rules?direction=' + this.direction, { headers: this.getAuthHeaders() });
      this.rules = data;
    },

    async saveRule(rule) {
      try {
        if (!this.$refs.form.validate()) return;

        if (this.editedRule._id) {
          await axios.put(process.env.VUE_APP_BASE_URL + `/rules/call-rules/${this.editedRule._id}`, this.editedRule, { headers: this.getAuthHeaders() });
        } else {
          await axios.post(process.env.VUE_APP_BASE_URL + '/rules/call-rules', this.editedRule, { headers: this.getAuthHeaders() });
        }
        this.fetchRules();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    async deleteRule(id) {
      await axios.delete(process.env.VUE_APP_BASE_URL + `/rules/call-rules/${id}`, { headers: this.getAuthHeaders() });
      this.fetchRules();
    },

    async toggleActive(id, active) {
      await axios.patch(process.env.VUE_APP_BASE_URL + `/rules/call-rules/${id}/active`, { active }, { headers: this.getAuthHeaders() });
    },
    async confirmDelete(item) {
      try {
        if (confirm(`האם למחוק את החוק "${item.description}"?`)) {
          await axios.delete(process.env.VUE_APP_BASE_URL + `/rules/call-rules/${item._id}`, { headers: this.getAuthHeaders() });
          this.fetchRules();
        }
      } catch (error) {
        console.log(error);
      }

    },
    addCondition() {
      this.editedRule.conditions.push({ type: 'startsWith', value: '' });
    },
    removeCondition(index) {
      this.editedRule.conditions.splice(index, 1);
    },
  }
}
</script>

<style scoped>
.v-icon {
  cursor: pointer;
  margin: 0 5px;
}
</style>