<template>
  <div style="margin-top: 30px;">
     <AgentMenu @barUploadItemSelected="openSelected" :routerSelected="routerSelected" />
  
  <FirstAgentComp v-if="itemSelected === 'page1'" />
  <SecondAgentComp v-if="itemSelected === 'page2'" />
  <ThirdAgentComp v-if="itemSelected === 'page3'" />
  </div>
  </template>
  <script>
  import FirstAgentComp from '@/components/agent/FirstAgentComp'
  import SecondAgentComp from '@/components/agent/SecondAgentComp'
  import ThirdAgentComp from '@/components/agent/ThirdAgentComp'
  import Auth from '@/services/auth.service.js'
  import AgentMenu from '@/components/agent/AgentMenu'
  
  export default {
    name: 'agent',
    components: {
      AgentMenu,
      FirstAgentComp,
      SecondAgentComp,
      ThirdAgentComp
    },
    data: () => ({
      itemSelected: "page1",
      routerSelected: "",
    }),
    methods: {
      openSelected(itemNum) {
        this.itemSelected = itemNum;
      },
    },
    created() {
      document.title = 'סוכן | clickcall'
      const pageParam = this.$route.params.page;
      if (pageParam) {
        this.routerSelected = pageParam;
       this.openSelected(pageParam)
      }else{
        this.openSelected("page1")
      }
  
      let auth = Auth.checkAuth();
      if (!auth) {
        this.$router.replace({ name: "login" })
      }
    },
  }
  </script>
  <style scoped>
  /* costume scroolbar body */
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

 /* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
    width: 0px !important;
}
  </style>