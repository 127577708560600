import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '@/services/auth.service.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAdminSwitched: false,
    isAgentSwitched: false,
    lineNumber: Auth.getLineNumber(),
    userName: Auth.getUserName(),
    business_name: "| " + Auth.getUserBusinessName(),
    role: Auth.getUserRole()
  },
  mutations: {
    TOGGLE_USER_DETAILS() {
      this.state.lineNumber = Auth.getLineNumber();
      this.state.userName = Auth.getUserName();
      this.state.business_name = "| " + Auth.getUserBusinessName();
      this.state.role = Auth.getUserRole();
    },
    TOGGLE_ADMIN_SWITCH() {
      this.state.isAdminSwitched = !this.state.isAdminSwitched;
      this.state.lineNumber = Auth.getLineNumber();
      this.state.userName = Auth.getUserName();
      this.state.business_name = "| " + Auth.getUserBusinessName();
      this.state.role = Auth.getUserRole();
    },
    TOGGLE_AGENT_SWITCH() {
      this.state.isAgentSwitched = ! this.state.isAgentSwitched;
      this.state.lineNumber = Auth.getLineNumber();
      this.state.userName = Auth.getUserName();
      this.state.business_name = "| " + Auth.getUserBusinessName();
      this.state.role = Auth.getUserRole();
    }
  },
});
