import { render, staticRenderFns } from "./SubscribesListView.vue?vue&type=template&id=382c5466&scoped=true&"
import script from "./SubscribesListView.vue?vue&type=script&lang=js&"
export * from "./SubscribesListView.vue?vue&type=script&lang=js&"
import style0 from "./SubscribesListView.vue?vue&type=style&index=0&id=382c5466&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382c5466",
  null
  
)

export default component.exports