<template>
    <div style="display: flex; justify-content: center;  align-items: center; ">
        <div id="barUpload" style="display: flex;  padding-left: 10px !important; padding-right: 10px !important;">

            <div class="barUploadItem" @click="openItemChoosed('1')"
                :style="selectedItem === 'page1' ? `width: ${itemWidth}%; background-color: #0d2c6d;` : `width:${itemWidth}%;`">
                <span class="font-responsive"
                    :style="selectedItem === 'page1' ? 'color: white;' : 'color: #0d2c6d;'">
                    ניהול משתמשים
                </span>
            </div>
            <div class="barUploadItem" @click="openItemChoosed('2')"
                :style="selectedItem === 'page2' ? `width: ${itemWidth}%; background-color: #0d2c6d;` : `width:${itemWidth}%;`">
                <span class="font-responsive"
                    :style="selectedItem === 'page2' ? 'color: white;' : 'color: #0d2c6d;'">
                    מאגר מספרים
                </span>
            </div>
            <div class="barUploadItem" @click="openItemChoosed('3')"
                :style="selectedItem === 'page3' ? `width: ${itemWidth}%; background-color: #0d2c6d;` : `width:${itemWidth}%;`">
                <span class="font-responsive"
                    :style="selectedItem === 'page3' ? 'color: white;' : 'color: #0d2c6d;'">
                    חיפוש מספר
                </span>
            </div>
            <div class="barUploadItem" @click="openItemChoosed('4')"
                :style="selectedItem === 'page4' ? `width: ${itemWidth}%; background-color: #0d2c6d;` : `width:${itemWidth}%;`">
                <span class="font-responsive"
                    :style="selectedItem === 'page4' ? 'color: white;' : 'color: #0d2c6d;'">
                    חבילות
                </span>
            </div>
            <div class="barUploadItem" @click="openItemChoosed('5')"
                :style="selectedItem === 'page5' ? `width: ${itemWidth}%; background-color: #0d2c6d;` : `width:${itemWidth}%;`">
                <span class="font-responsive"
                    :style="selectedItem === 'page5' ? 'color: white;' : 'color: #0d2c6d;'">
                    Rules
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routerSelected: String
    },
    data: () => ({
        selectedItem: "page1", // This will store the ID of the selected item
        itemWidth: "20",
    }),
    methods: {
        openItemChoosed(itemNum) {
            if (this.$route.params.page !== `page${itemNum}`) {
                this.$router.replace({ name: `admin`, params: { page: `page${itemNum}` } });
                this.selectedItem = `page${itemNum}`;
                this.$emit('barUploadItemSelected', "page" + itemNum)
            }
        }
    },
    mounted() {
        // Check if the window width is less than or equal to 600px
        if (window.innerWidth <= 600) {
            this.itemWidth = "50";
        } else {
            this.itemWidth = "20";
        }
    },
    created() {
        this.selectedItem = this.routerSelected;
    },
}
</script>
<style scoped>
#barUpload {

    width: 90%;
    /* background-color: #adacaa2c; */
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
    ;
    height: 70px;
    margin-bottom: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Add rounded corners */
    border-radius: 8px;
}

.barUploadItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid
}

.barUploadItem:hover {
    background: #0d2c6d;
    cursor: pointer;
}

.barUploadItem:hover span {
    color: white !important;
}


.font-responsive {
    font-size: 12px;
    font-weight: 500
}

@media (min-width: 600px) {
    .font-responsive {
        font-size: 20px;
        font-weight: 500
    }
}
</style>

 